import styles from './style.css?inline';

const styleTag = document.createElement('style');
styleTag.textContent = styles;
document.head.appendChild(styleTag);

import autocompleter from '../autocomplete/autocomplete-input';
'../autocomplete/autocomplete-input';

export default function routeBuilderComponent() {
  createEventHandlers();
  checkCanAddNewStop();
  document.getElementById('routing-type-car').addEventListener('click', (e)=> transportMethodSelected(e));
  document.getElementById('routing-type-bike').addEventListener('click', (e)=> transportMethodSelected(e));
  document.getElementById('routing-type-foot').addEventListener('click', (e)=> transportMethodSelected(e));

  let searchInputStart = document.getElementById('searchStart');
  let searchInputStartUrl = document.getElementById('searchStartUrl');

  let searchInputEnd = document.getElementById('searchEnd');
  let searchInputEndUrl = document.getElementById('searchEndUrl');

  let addStopButton = document.getElementById('addStop');
  addStopButton.addEventListener('click', createStop);

  autocompleter(searchInputStart);
  autocompleter(searchInputEnd);

  let clearButtonStart = document.getElementById('clearButtonStart');
  let clearButtonEnd = document.getElementById('clearButtonEnd');

  searchInputStart.addEventListener("input", () => {
    clearButtonStart.style.display = searchInputStart.value ? "block" : "none";
    searchInputStartUrl.value = '';
  });

  searchInputStart.addEventListener('itemSelected', function(e) {
    searchInputStart.parentNode.classList.remove('input-error');
    searchInputStartUrl.value = e.detail.value;
  });

  searchInputEnd.addEventListener("input", () => {
    clearButtonEnd.style.display = searchInputEnd.value ? "block" : "none";
    searchInputEndUrl.value = '';
  });

  searchInputEnd.addEventListener('itemSelected', function(e) {
    searchInputEnd.parentNode.classList.remove('input-error');
    searchInputEndUrl.value = e.detail.value;
  });

  clearButtonStart.addEventListener("click", () => {
    searchInputStart.value = '';
    searchInputStartUrl.value = '';
    searchInputStart.focus();
    clearButtonStart.style.display = "none";
  });

  clearButtonEnd.addEventListener("click", () => {
    searchInputEnd.value = '';
    searchInputEndUrl.value = '';
    searchInputEnd.focus();
    clearButtonEnd.style.display = "none";
  });

  let routerSubmitBtn = document.getElementById('routerSubmitBtn');
  routerSubmitBtn.addEventListener('click', (e) => {
    buildRoute();
  });
}

function transportMethodSelected(e) {
  let containerDiv = document.querySelector('.transport-selection-container');
  for (let i in Array.prototype.slice.call(containerDiv.children)) {
    containerDiv.children[i].classList.remove('transport-selected');
  }
  e.target.parentNode.classList.add('transport-selected');
//  map.fire('transportchanged', {value:method}, false);
}

function createStop() {
  stopsCounter++;

  checkCanAddNewStop();

  let inputContainer = document.createElement('div');
  inputContainer.className = 'input-container';

  let stopIcon = document.createElement('i');
  stopIcon.className = 'router-stop-icon';
  inputContainer.appendChild(stopIcon);

  let inputField = document.createElement('input');
  inputField.classList.add('router-input');
  inputField.classList.add('router-input-stop');
  inputField.name = `searchStop${stopsCounter}`;
  inputField.id = `searchStop${stopsCounter}`;
  inputField.dataset.container = stopsCounter;

  inputField.autocomplete = 'off';
  
  autocompleter(inputField);

  let inputFieldUrl = document.createElement('input');
  inputFieldUrl.name = `searchStopUrl${stopsCounter}`;
  inputFieldUrl.id = `searchStopUrl${stopsCounter}`;
  inputFieldUrl.classList.add('input-field-url');
  inputFieldUrl.classList.add('input-field-url-stop')
  inputFieldUrl.type = 'hidden';
  inputContainer.appendChild(inputFieldUrl);

  inputField.addEventListener('itemSelected', (e) => {
    inputFieldUrl.value = e.detail.value
    inputField.parentNode.classList.remove('input-error');
  });

  inputContainer.appendChild(inputField);

  let clearButton = document.createElement('span');
  clearButton.classList.add('clear-button');
  clearButton.classList.add('clear-button-stop');
  clearButton.id = `clearButton${stopsCounter}`;
  clearButton.dataset.container = stopsCounter;
  clearButton.innerHTML = '&times;';

  clearButton.addEventListener('click', () => clearInputField(clearButton));

  inputField.addEventListener('input', () => onTypeInputField(inputField))

  inputContainer.appendChild(clearButton);    

  let removeStopButton = document.createElement('button');
  removeStopButton.className = 'remove-stop';
  removeStopButton.innerHTML = '&nbsp;';
  removeStopButton.setAttribute('data-container', stopsCounter);
  removeStopButton.addEventListener('click', (e) => {
    e.target.parentNode.remove()
    stopsCounter--;
    checkCanAddNewStop();
  });
  inputContainer.appendChild(removeStopButton);

  let inputFields = document.getElementById('routerInputFields');
  let addStopContainer = document.getElementById('addStopContainer');
  inputFields.insertBefore(inputContainer, addStopContainer);

  inputField.focus();
}

function buildRoute() {
  clearErrors();

  let hasErrors = false;
  document.querySelectorAll('.input-field-url').forEach((item) => {
    if (item.value.length === 0 ) {
      item.parentNode.classList.add('input-error');
      hasErrors = true;
    }
  });

  if (!hasErrors) {
    let stops = '';
    document.querySelectorAll('.input-field-url-stop').forEach((item) => {
      stops += item.value + ',';
    });

    stops = stops.substring(0, stops.length-1);

    let origin = document.querySelector("#searchStartUrl").value;
    let destination = document.querySelector("#searchEndUrl").value;
    let baseUrl = document.querySelector("#baseUrl").value;
    let urlPrefix = document.querySelector("#urlPrefix").value;
    let urlSeparator = document.querySelector("#urlSeparator").value;
  
    let windowUrl = baseUrl + '/' + urlPrefix + origin + urlSeparator + destination;

    let routingType = getRoutingType();

    if (stops.length > 0) {
      windowUrl += '?s=' + stops
      if (routingType !== 'car') {
        windowUrl += '&t=' + routingType;
      }
    } else {
      if (routingType !== 'car') {
        windowUrl += '?t=' + routingType;
      }  
    }

    window.location.href = windowUrl
  }
}

function getRoutingType() {
  let type = 'car';
  let typeCar = document.getElementById('routing-type-car');
  let typeBike = document.getElementById('routing-type-bike');
  let typeFoot = document.getElementById('routing-type-foot');

  if (typeCar.checked) type = 'car';
  if (typeBike.checked) type = 'bike';
  if (typeFoot.checked) type = 'foot';

  return type;
}

function clearErrors() {
  document.querySelectorAll('.input-field-url')
  .forEach(item => item.parentNode.classList.remove('input-error'));
}

function clearInputField(clearButton) {
  let inputField = document.getElementById('searchStop' + clearButton.dataset.container );
  let inputFieldUrl = document.getElementById('searchStopUrl' + clearButton.dataset.container );
  inputField.value = '';
  inputFieldUrl.value = '';
  inputField.focus();
  clearButton.style.display = "none";
}

function onTypeInputField(inputField) {
  let clearButton = document.getElementById('clearButton' + inputField.dataset.container );
  let inputFieldUrl = document.getElementById('searchStopUrl' + inputField.dataset.container );

  clearButton.style.display = inputField.value ? "block" : "none";
  inputFieldUrl.value = '';
}

function createEventHandlers() {
  // clear field
  document.querySelectorAll('.clear-button-stop').forEach((item) => {
    item.addEventListener('click', e => clearInputField(item));
  });

  document.querySelectorAll('.router-input-stop').forEach((item) => {
    let inputFieldUrl = document.getElementById('searchStopUrl' + item.dataset.container);

    autocompleter(item);
    item.addEventListener('itemSelected', (e) => {
      inputFieldUrl.value = e.detail.value;
      item.parentNode.classList.remove('input-error');
    });

    item.addEventListener('input', e => onTypeInputField(item));
  });

  document.querySelectorAll('.remove-stop').forEach(item => 
    item.addEventListener('click', () => {
      item.parentNode.remove();
      stopsCounter--;
      checkCanAddNewStop();
    }));
}

function checkCanAddNewStop() {
  let addStopContainer = document.getElementById('addStop');
  if (stopsCounter > 1) {
    addStopContainer.style.visibility = 'hidden';
  } else {
    addStopContainer.style.visibility = 'visible';
  }
}
import 'custom-event-polyfill';
import autocomplete from "../components/autocomplete/autocomplete-input";
import routeBuilderComponent from '../components/route-builder';


export function loadAutoComplete(input) {
  autocomplete(input);
}

export function search() {
  let messages = {
    en : {
      sameOriginDestiny : "Choose a different destination.",
      invalidSearch : "Please choose a valid origin and a valid destination."
    },
    it : {
      sameOriginDestiny : "Scegli una destinazione diversa.",
      invalidSearch : "Si prega di scegliere un'origine valida e una destinazione valida."
    },
    es : {
      sameOriginDestiny : "Origen y destino deben ser diferentes.",
      invalidSearch : "Seleccione un origen y un destino válido."
    },
    fr : {
      sameOriginDestiny : "Origine et destination doivent être différents.",
      invalidSearch : "Sélectionnez une source et une destination valide."
    },
    pt : {
      sameOriginDestiny : "Origem e destino devem ser diferentes.",
      invalidSearch : "Selecione uma origem e um destino válidos."
    }
  }

  let lang = document.querySelector("#baseLanguage").value;
  let origin = document.querySelector("#searchStartUrl").value;
  let destiny = document.querySelector("#searchEndUrl").value;
  let baseUrl = document.querySelector("#baseUrl").value;
  let urlPrefix = document.querySelector("#urlPrefix").value;
  let urlSeparator = document.querySelector("#urlSeparator").value;

  let viaParams = '';

  if (document.querySelector("#viaId0")) {
    viaParams = '?' + document.querySelector("#viaId0").value;
  }

  if (origin !== '' && destiny !== '') {
    if (origin !== destiny) {
      window.location = baseUrl + '/' + urlPrefix + origin + urlSeparator + destiny + viaParams;
    } else {
      alert(messages[lang]['sameOriginDestiny']);
      return false;
    }
  } else {
    alert(messages[lang]['invalidSearch']);
    return false;
  }
}


export function routeBuilder(map) {
  routeBuilderComponent(map);
}

export function showHideSearchForm() {
  let searchIcon = document.getElementById('search-icon');
  let routerBuilderWindow = document.getElementById('router-builder-window');

  if (routerBuilderWindow.classList.contains('router-open')) {
    routerBuilderWindow.classList.remove('router-open');
    searchIcon.style.display = 'inline-block';
  } else {
    routerBuilderWindow.classList.add('router-open');
    searchIcon.style.display = 'none';
  }
}

export function hideSearchForm() {
  let routerBuilderWindow = document.getElementById("router-builder-window");
  let searchIcon = document.getElementById("search-icon");

  document.addEventListener("click", (event) => {
    const clickedElement = event.target;
    if (!routerBuilderWindow.contains(clickedElement) 
      && event.target !== searchIcon
      && event.target.className !== 'remove-stop') {
      routerBuilderWindow.classList.remove("router-open");
      searchIcon.style.display = "inline-block";
    }
  });
}
import styles from './autocomplete.css?inline';

const styleTag = document.createElement('style');
styleTag.textContent = styles;
document.head.appendChild(styleTag);

import autocomplete from "autocompleter";

export default function autocompleter(input) {
  return autocomplete({
    input: input,
    preventSubmit: true,
    disableAutoSelect: false,
    minLength: 3,
    fetch: function(text, update) {
        text = text.toLowerCase();
        ajaxSource(text.toLowerCase(), update)
    },
    onSelect: function(item) {        
        input.value = item.data;
        input.dispatchEvent(new CustomEvent('itemSelected', {detail: item}));
    },
    render: function(item, currentValue) {
      const div = document.createElement("div");
      div.className = 'autocomplete-item';
      
      let html = `
      <img class="icon" src="/assets/images/icons/place.svg">
      <div class="item-title">${item.data}</div>`;

      div.innerHTML = html;
      return div;
    }
  });
}

function ajaxSource(term, response) {
  try { xhr.abort(); } catch (e) { }

  var request = new XMLHttpRequest();
  request.open('GET', document.querySelector('#baseUrl').value + '/searchCity?query=' + term, true);
  request.onload = function () {
    if (this.status >= 200 && this.status < 400) {
      var suggestions = JSON.parse(this.response).suggestions;
      response(suggestions);
    } else {
      console.log('Error loading city');
    }
  };

  request.onerror = function () {
    console.log('Server connection error');
  };
  request.send();
}